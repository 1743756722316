import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, HashRouter} from 'react-router-dom';

{/* <Routes>
<Route path="/BeMyValentine" element={<Valentine/>}/>
<Route path="/BeMyValentine/yes" element={<Yes/>}/>
<Route path="/BeMyValentine/no" element={<No/>}/>
<Route path="/BeMyValentine/No" element={<No/>}/>
<Route path="/yes" element={<Yes/>}/>
<Route path="/#/no" element={<No/>}/>
<Route path='no' element={<No/>}/>
<Route path='#no' element={<No/>}/>
<Route path='No' element={<No/>}/>
<Route path='#No' element={<No/>}/>
<Route path='/' element={<Valentine/>}/>
</Routes> */}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <HashRouter basename=''>
        <App />
      </HashRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
